<template>
  <div class="workflow-sdk">
    <!-- bbbb{{ $route.query }} <br />

    {{ apikey }} <br />
    {{ orgId }} <br />
    {{ name }} <br />
    {{ triggers }} <br />
    {{ form }} -->

    <workflow
      :name="name"
      :form="form"
      @destroy="closeSdk"
      :apiKey="key"
      :organizationId="orgId"
      :triggers="triggers"
    ></workflow>
  </div>
</template>

<script>
import workflow from "./init.vue";

export default {
  // store,
  name: "App",
  components: {
    workflow,
  },

  methods: {
    closeSdk() {
      window.parent.postMessage({ closeSDK: true }, "*");
    },
  },

  computed: {
    key() {
      return this.$route.query.key || "";
    },
    name() {
      return this.$route.query.name || "";
    },
    orgId() {
      return this.$route.query.orgId || "";
    },

    form() {
      return this.$route.query.form || "";
    },
    triggers() {
      const triggers = this.$route.query.triggers || [
        "Form",
        "Invoice",
        "Payment",
      ];

      if (triggers === "all") {
        return ["Form", "Invoice", "Payment"];
      } else if (triggers === "form") {
        return ["Form"];
      } else if (triggers === "invoice") {
        return ["Invoice"];
      } else if (triggers === "payment") {
        return ["Payment"];
      } else if (triggers == null || triggers.length === 0) {
        return ["Form", "Invoice", "Payment"];
      } else {
        return triggers.split(",");
      }
    },
  },
};
</script>

<style>
.theme--light,
body,
html,
.theme--light.v-application,
.v-application .primer {
  background-color: transparent;
  background: transparent;
  border-color: none;
}

.v-application .primer {
  background-color: transparent !important;
  border-color: transparent !important;
}

.workflow-sdk {
  height: 100vh;
  background: transparent;
  background-color: transparent;
  width: 100vw;
  z-index: 9999px;
}
</style>
